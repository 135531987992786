<template>
    <section class="main-content-section">
        <div class="grid">
            <div class="col-1-1 center">
                <h2 class="page-title">{{orderList}}</h2>
            </div>
            <LoadingSpinner v-if="isLoading" />
            <div v-else class="col-8-12 push-2-12 sm-col-1-1 sm-no-push">
                <div class="textpage">
                    <div class="btn-container flex justify-center align-center">
                        <button @click="getOrderListForStore()" class="btn btn-primary alternative">{{download}}</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import agent  from '../../api/agent';
import {mapGetters} from 'vuex';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import { downloadDocument } from '../../utils/downloadService';
export default {
    name: 'OrderListForStore',
    components: {LoadingSpinner},
    data() {
        return {
            isLoading: false
        }
    },
    computed: {
        ...mapGetters(['download', 'orderList', 'metaTitle'])
    },
    created() {
        this.setDocumentTitle();
    },
    methods: {
        setDocumentTitle() {
            document.title = this.orderList + this.metaTitle;
        },
        async getOrderListForStore() {
            try {
                this.isLoading = true;
                const result = await agent.report.orderListForStore();
                this.isLoading = false;
                downloadDocument(result, 'pdf', 'order-list-for-store');
            } catch (error) {
                console.log(error.response);
                this.isLoading = false;
            }
        }
    }
}
</script>

<style>
</style>